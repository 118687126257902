import React, { useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { GrClose } from "react-icons/gr";
import { getMeetingRoomAvailableSlots } from "../../../services/mrbs.service";
import { ERROR_MESSAGES } from "../../../utils/constants/errorMessages";
import { DataNotFound, Nav, RoomDetailsTable } from "../../index";
import "../RoomCards/RoomCard.css";
function RoomSlotsDetails({ isShow, onHide, roomName, roomId }) {
  const [dates, setDates] = useState(new Date().toISOString().split("T")[0]);
  const [cardData, setCardData] = useState([]);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [error, setError] = useState(false);
  useEffect(() => {
    const fetchData = async () => {
      if (selectedDate instanceof Date && !isNaN(selectedDate?.getTime())) {
        const formattedDate = selectedDate?.toLocaleDateString("en-GB");
        const formDate = {
          meetRoomId: roomId,
          date: formattedDate,
        };
        try {
          const meetingRoomSlots = await getMeetingRoomAvailableSlots(formDate);
          setError(false);
          setCardData(meetingRoomSlots);
        } catch (error) {
          setError(true);
          console.error(ERROR_MESSAGES.fetchDataError, error);
        }
      }
    };

    if (isShow && roomId !== null && selectedDate !== null) {
      fetchData();
    }
  }, [roomId, selectedDate, isShow]);

  const handleDateSubmit = async (e) => {
    e.preventDefault();
    const [day, month, year] = dates.split("/");
    const selectedDateObject = new Date(`${year}/${month}/${day}`);
    if (!isNaN(selectedDateObject.getTime())) {
      const formattedDate = selectedDateObject?.toLocaleDateString("en-GB");
      setSelectedDate(formattedDate);

      const formDate = {
        meetRoomId: roomId,
        date: formattedDate,
      };
      try {
        const meetingRoomSlots = await getMeetingRoomAvailableSlots(formDate);
        setError(false);
        setCardData(meetingRoomSlots);
      } catch (error) {
        setError(true);
        console.error(ERROR_MESSAGES.fetchDataError, error);
      }
    }
  };
  return (
    <Modal
      show={isShow}
      onHide={onHide}
      centered
      dialogClassName="custom-modal modal-lg modal-fullscreen"
    >
      <Nav isBookingDetails={true} onDetailsHide={onHide} />
      <div className="room-details-heading-box">
        <div className="room-details-heading">
          <h1>
          Meeting Room Booking Details :{" "}
            <span>
              <strong> {roomName}</strong>
            </span>
          </h1>{" "}
        </div>
        <button className="cross-icon-btn" onClick={onHide}>
          <GrClose size={15} />
        </button>
      </div>
      <Modal.Body className="slots-table-container">
        <form onSubmit={handleDateSubmit}>
          <div className="slot-date-box">
            <Form.Control
              className="slots-input-box"
              centered
              type="date"
              name="date"
              onChange={(e) => setDates(e.target.value)}
              value={dates}
              id="inputPassword5"
              min={new Date().toISOString().split("T")[0]}
              required
            />
            <button type="submit" className="btn btn-dark">
              Submit
            </button>
          </div>
        </form>
        <hr />
        {error ? (
          <DataNotFound error="Details Not Found" />
        ) : (
          <RoomDetailsTable userData={cardData} isSlotDetails={true} />
        )}
      </Modal.Body>
    </Modal>
  );
}

export default RoomSlotsDetails;
